import { graphql } from 'gatsby';
import React from 'react';
import { ArticleCard } from '../../components/article_card/article_card';
import { ContentWrapper } from '../../components/content_wrapper/content_wrapper';
import { SEO } from '../../components/seo';
import { GatsbyPageProps } from '../../gatsby-types';
import Layout from '../../layouts/layout';
import { PrismicArticle } from '../../models/prismic_article';
import { Style } from '../../models/style';
import * as styles from './technique.module.less';

export const query = graphql`
  query($article_tag_name: String) {
    allPrismicArticle(
      filter: { tags: { eq: $article_tag_name } }
      sort: { fields: data___publication_date, order: DESC }
    ) {
      nodes {
        ...ArticleCardFragment
      }
    }
  }
`;

const TechniquePage = (props: GatsbyPageProps) => {
  const style = props.pageContext.style as Style;
  if (!style) {
    console.log('Style not found');
    return null;
  }

  const { display_name } = style;
  const articles = props.data.allPrismicArticle.nodes as PrismicArticle[];

  return (
    <Layout>
      <SEO title={display_name} />
      <ContentWrapper>
        <article className={styles.page}>
          <h1>{display_name}</h1>

          <ol className={styles.articles}>
            {articles.map((article) => (
              <li className={styles.article} key={article.uid}>
                <ArticleCard article={article} />
              </li>
            ))}
          </ol>
        </article>
      </ContentWrapper>
    </Layout>
  );
};

export default TechniquePage;
